import { createI18n } from "vue-i18n";
import EN from "./en";
import ZH from "./zh";

const messages = {
    chinese:ZH,
    english:EN,
};

if (localStorage.LANG) {
    var current_lang = localStorage.LANG;
}else{
    current_lang = "chinese";
}

const i18n = createI18n({
    locale: current_lang, //设置当前语言类型
    legacy: false, //如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, //全局注册$t方法
    messages,
});

export default i18n;
