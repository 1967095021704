export default{
    // nav
    logo: "ESS SOLUTIONS",
    home: "Home",
    products: "Products",
    news: "News",
    faqs: "FAQs",
    about_us: "About Us",
    contact_us: "Contact Us",
    technical_support: "Technical Support",
    switch_langegua: "More langegua",
    //sub nav
    exhibition: "Exhibition",
    certifications: "Certifications",
    
    //language
    chinese: "Chinese",
    english: "English",

    //footer
    message: "Message",
    sales_add_content: "12th Floor, Building B4, Yunzhi Science Park, Dongzhou Community, Guangming Street, Guangming District, Shenzhen.",
    factory_add_content: "Area 505, 5th Floor, Building 3, Jindi Weiwuhou Science and Technology Innovation Park, No. 99 Wuke West Fourth Road, Wuhou District, Chengdu City.",
    work_time_content: "Every day from 10:00 am to 6:00 pm.",
    sales_add: "R&D/Sales Address",
    factory_add: "Factory Address",
    telephone: "Telephone",
    work_time: "Work Time",
    email: "E-mail",
    copyright: "Copyright",

    //message box
    message_title: "Leave Your Message",
    message_name: "* Name",
    message_email: "* Email (We will reply you via email in 24 hours)",
    message_contact: "Phone/WhatsApp/WeChat (Very important)",
    message_content: "* Enter product details such as size, color, materials etc. and other specific requirements to receive an accurate quote.",
    send: "Send",

    //prompt 提示
    data_null: "There is currently no data available",
    data_error: "Data Error",
    send_success: "Message sent successfully",
    send_fail: "Message sending failed",

    //home text
    welcome: "Welcome to our company",
    power_bms: "Power BMS",
    rv_bms: "RV BMS",
    relay_bms: "Relay BMS",
    inverter_bms: "Inverter BMS",
    active_balancer: "Active Balancer",
    gps_positioning_system: "GPS Positioning System",
    home_ess: "Home ESS",
    industrial_ess: "Industrial ESS",

    power_bms_introduce: "Power BMS is suitable for electric bicycles,Electric tricycle, electric golf cart,Low speed electric vehicle.",
    rv_bms_introduce: "RV BMS Suitable for RVCar starting power supply",
    relay_bms_introduce: "Relay BMS Suitable for Forklifts,electric sailboats, golf carts,Sightseeing bus.",
    inverter_bms_introduce: "Suitable for home energy storage systems.Widely compatible with inverters in the market.",
    active_balancer_introduce: "Applied to high-power energy storageBase station backup power supplySolar power plants, etc Balanced maintenance of lithium battery packs.",
    gps_positioning_system_introduce: "GPS Beidou positioning remote controPositioning / Monitoring / Protection / Internet of ThingsParameter information / remote power outagemileage statistics / motion trajectory.",
    home_ess_introduce: "Outdoor power supply systemHome energy storage systemIndustrial and commercial energy storage system.",
    industrial_ess_introduce: "Industrial energy storage systemCommercial energy storage system.",

    voltage: "Voltage",
    current: "Current",
    active_balancer_current: "Active Balancer Current",
    model: "Model",

    //introduce
    introduct_tlt: "Master the world's leading power battery management system",
    introduct_subtlt: "Four core technologies",
    introduct_1_tlt: "Active equilibrium",
    introduct_1: "Bidirectional active equalization technology can adjust the capacity difference of battery pack in real time, effectively prolong the mileage and service life, and break through the bottleneck of inconsistent charge and discharge of battery cells.",
    introduct_2_tlt: "Wireless transmission",
    introduct_2: "The extremely empty battery management system has built-in wireless transmission technology to realize real-time monitoring and active prediction and analysis of battery pack operation data. It can be read synchronously through mobile phones, computers and other clients, and carry out early warning, alarm and protection as needed.",
    introduct_3_tlt: "Functional safety",
    introduct_3: 'BMS manufacturers that import the latest international safety standard iso26262 "road vehicle functional safety standard" for electric vehicles have passed strict standard tests.',
    introduct_4_tlt: "Capacity management",
    introduct_4: "The capacity management function of extreme space products based on model and empirical data analysis can accurately predict the mileage of electric vehicles and realize battery intelligent management.",
    introduct_more_btn: "Leader in active equalization technology for lithium batteries",
    
    //home products
    featured_products: "Featured Products",
    details: "Details",

    //home about us
    company_name: "Jkbms ElectronicTechnology Co., Ltd.",
    about_us_introduct: "Jkbms ElectronicTechnology Co., Ltd.  specializes in the research and development of power battery management systems. As the leader in lithium battery active balancing technology,  Jkbms ElectronicTechnology Co., Ltd. controls four core technologies of active balancing(wireless communication, functional safety, and capacity management) in the world. It adheres to the business philosophy of pursuing product excellence, assisting environmental protection, and harvesting beautiful skies, writing a new chapter in the electric vehicle industry and contributing to environmental protection.",
    about_us_more_btn: "Learn More",

    //products
    inquiry: "Inquiry",
    detail: "Detail",

    categories: "Categories",
    product_info: "Product Information",
    send_email: "Send Us An Email",

    //faq
    faq_about_us: "WANT TO WORK WITH US?",
    faq_1_question: "What are your prices?",
    faq_1_answer: "Our prices are subject to change depending on supply and other market factors. We will send you an updated price list after your company contact us for further information.",
    faq_2_question: "Do you have a minimum order quantity?",
    faq_2_answer: "Yes, we require all international orders to have an ongoing minimum order quantity. If you are looking to resell but in much smaller quantities, we recommend you check out our website.",
    faq_3_question: "Can you supply the relevant documentation?",
    faq_3_answer: "Yes, we can provide most documentation including Certificates of Analysis / Conformance; Insurance; Origin, and other export documents where required.",
    faq_4_question: "What is the average lead time?",
    faq_4_answer: "For samples, the lead time is about 7 days. For mass production, the lead time is 20-30 days after receiving the deposit payment. The lead times become effective when we have received your deposit, and we have your final approval for your products. If our lead times do not work with your deadline, please go over your requirements with your sale. In all cases we will try to accommodate your needs. In most cases we are able to do so.",
    faq_5_question: "What kinds of payment methods do you accept?",
    faq_5_answer: "You can make the payment to our bank account, Western Union or PayPal: 30% deposit in advance, 70% balance against the copy of B/L.",
    faq_6_question: "What is the product warranty?",
    faq_6_answer: "We warranty our materials and workmanship. Our commitment is to your satisfaction with our products. In warranty or not, it is the culture of our company to address and resolve all customer issues to everyone’s satisfaction.",
    faq_7_question: "Do you guarantee safe and secure delivery of products?",
    faq_7_answer: "Yes, we always use high quality export packaging. We also use specialized hazard packing for dangerous goods and validated cold storage shippers for temperature sensitive items. Specialist packaging and non-standard packing requirements may incur an additional charge.",
    faq_8_question: "How about the shipping fees?",
    faq_8_answer: "The shipping cost depends on the way you choose to get the goods. Express is normally the most quickest but also most expensive way. By seafreight is the best solution for big amounts. Exactly freight rates we can only give you if we know the details of amount, weight and way. Please contact us for further information.",

    //news
    bms_rivacy_policy: "BMS Privacy Policy",
    encyclopedia_of_lithium_batteries: "Encyclopedia of Lithium Batteries",
    media_information: "Media Information",
    industry_information: "Industry Information",
    read_more: "Read More",

    //about us
    our_company: "Our Company",
    company_exterior_view: "Company exterior view",
    our_company_content: "Jkbms Electronic Technology Co., Ltd. was established in 2017, integrating R & D, production and sales, and specilizing in the production of lithium battery protective boards, such as LiFePO4 BMS, NMC BMS, LTO BMS, which can be used to energy storage, electric vehicles, electric tools, electric wheelchairs, AGVs, and forklifts, etc. The specifications of JKBMS are 4S - 25S, 24v-120v, and 5A-500A. Smart BMS, BT, UART, RS485, can, LCD and GPS are very popular in Europe, Asia and North America. We strive to continue to develop more high-tech BMS. Welcome to visit our company in person, and hope to establish a long-term cooperative relationship with you.",

    //contact us
    address: "Address",

    //technical support
    user_manual: "User's Manual",
    product_video: "Product Video",
    download_software: "Download Software",
    firmware_temporary_password: "Firmware temporary password",
    download_pc: "Download PC Software",
    download_bluetooth: "Download Bluetooth Software",
    download: "Download",

    //product video
    smart_bms: "Smart BMS",

    //page
    page_num: 'Page',
    next_page: 'Next',
    prev_page: 'Previous',
};
