import { createRouter, createWebHistory } from "vue-router"

const home = () => import("./view/home")

const products = () => import("./view/products");
const product = () => import("./view/product");

const news = () => import("./view/news");
const new_info = () => import("./view/new");

const faqs = () => import("./view/faqs");

const about_us = () => import("./view/aboutUs");
const certifications = () => import("./view/certifications");

const contact_us = () => import("./view/contactUs");

const technical_support = () => import("./view/technicalSupport");
const user_manual = () => import("./view/userManual");
const product_video = () => import("./view/productVideo");
const download_pc = () => import("./view/downloadPC");
const download_bluetooth = () => import("./view/downloadBluetooth");

const routes = [
    {
      path: "/home",
      name: "home",
      component: home,
    },
    {
      path: "/products",
      name: "products",
      component: products,
    },
    {
      path: "/news",
      name: "news",
      component: news,
    },
    {
      path: "/faqs",
      name: "faqs",
      component: faqs,
    },
    {
      path: "/about-us",
      name: "about_us",
      component: about_us,
    },
    {
      path: "/contact-us",
      name: "contact_us",
      component: contact_us,
    },
    {
      path: "/technical-support",
      name: "technical_support",
      component: technical_support,
    },
    {
      path: "/product",
      name: "product",
      component: product,
    },
    {
      path: "/new",
      name: "new",
      component: new_info,
    },
    {
      path: "/certifications",
      name: "certifications",
      component: certifications,
    },
    {
      path: '/user_manual',
      name: "user_manual",
      component: user_manual,
    },
    {
      path: '/product_video',
      name: "product_video",
      component: product_video,
    },
    {
      path: '/download_pc',
      name: "download_pc",
      component: download_pc,
    },
    {
      path: '/download_bluetooth',
      name: "download_bluetooth",
      component: download_bluetooth,
    },
    { path: "/", redirect: "/home" },
  ]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router
