export default{
    // nav
    logo: "ESS SOLUTIONS",
    home: "首页",
    products: "产品",
    news: "新闻中心",
    faqs: "常见问题解答",
    about_us: "关于我们",
    contact_us: "联系我们",
    technical_support: "技术支持",
    switch_langegua: "更多语言",
    //sub nav
    exhibition: "展览",
    certifications: "证书",

    //language
    chinese: "汉语简体",
    english: "英文",

    //footer
    message: "留言",
    sales_add_content: "深圳市光明区光明街道东周社区云智科园B4栋12层",
    factory_add_content: "成都市武侯区武科西四路99号金地威武侯科创园3幢5楼505区",
    work_time_content: "每天上午 10:00 至下午 6:00",
    sales_add: "研发/销售地址",
    factory_add: "工厂地址",
    telephone: "电话",
    work_time: "工作时间",
    email: "电子邮件",
    copyright: "版权所有",

    //message box
    message_title: "发送你的留言",
    message_name: "* 名称",
    message_email: "* 电子邮件（我们将在24小时内通过电子邮件回复您）",
    message_contact: "电话/WhatsApp/微信（非常重要）",
    message_content: "*输入产品详细信息，如尺寸、颜色、材料等，以及其他特定要求，以获得准确的报价。",
    send: "发送留言",

    //prompt 提示
    data_null: "数据为空",
    data_error: "数据错误",
    send_success: "留言成功",
    send_fail: "留言失败",

    //home text
    welcome: "欢迎来到我们公司",
    power_bms: "动力BMS",
    rv_bms: "房车BMS",
    relay_bms: "继电器BMS",
    inverter_bms: "逆变器BMS",
    active_balancer: "锂电池智能修复仪",
    gps_positioning_system: "GPS北斗定位遥控",
    home_ess: "ESS解决方案",
    industrial_ess: "工商业储能",

    power_bms_introduce: "智能BMS适用于电动自行车、电动三轮车、电动高尔夫球车、低速电动车。",
    rv_bms_introduce: "适用于房车电源，汽车启动电源。",
    relay_bms_introduce: "适用于叉车，电动帆船，高尔夫球车,观光车。",
    inverter_bms_introduce: "适用于家庭储能系统，广泛适配市场上逆变器。",
    active_balancer_introduce: "应用于大功率储能基站备用电源、太阳能电站等理电池组的均衡维修。",
    gps_positioning_system_introduce: "定位 / 监控 / 保护 / Things互联网参数信息 / 远程停电 / 里程统计 / 运动轨迹",
    home_ess_introduce: "室外供电系统、家用储能系统、工业和商业储能系统。",
    industrial_ess_introduce: "工业储能系统，商业储能系统。",

    voltage: "电压",
    current: "电流",
    active_balancer_current: "均衡电流",
    model: "型号",

    //home introduce
    introduct_tlt: "掌握主动均衡的动力电池管理系统",
    introduct_subtlt: "四大核心技术",
    introduct_1_tlt: "主动均衡",
    introduct_1: "双向主动均衡技术，实时调整电池组容量差异，有效延长续航里程及使用寿命，突破电池单体充放电不一致的瓶颈。",
    introduct_2_tlt: "无线传输",
    introduct_2: "极空电池管理系统内置无线传输技术，实现电池组运行数据的实时监控和主动预测分析，可通过手机，电脑等客户端同步读取，根据需要进行预警、报警和保护。",
    introduct_3_tlt: "功能安全",
    introduct_3: "导入电动汽车国际安全标准ISO26262《道路车辆功能安全标准》的BMS厂家，产品通过严格的标准测试。",
    introduct_4_tlt: "容量管理",
    introduct_4: "极空产品基于模型及经验数据分析的容量管理功能，可以精准预测电动汽车的续航里程，实现电池智能管理。",
    introduct_more_btn: "锂电池主动均衡技术倡导者",

    //home products
    featured_products: "特色产品",
    details: "详情",

    //home about us
    company_name: "深圳极空科技有限公司",
    about_us_introduct: "深圳极空科技有限公司是一家专注于动力电池BMS管理系统研发企业，集研发、生产、销售于一体，专业生产锂电池保护板，可用于能源仓储、电动汽车、电动工具、电动轮椅、AGV、叉车等。作为“锂电池主动均衡技术的倡导者”， 深圳极空科技掌控动力电池管理系统四大核心技术：主动均衡、无线通讯、功能安全、容量管理。极空科技秉承“追求产品细节、助力环保事业,收获美丽天空”的经营理念谱写电动汽车行业新篇章为环保事业贡献自己的力量！",
    about_us_more_btn: "了解更多",

    //products
    inquiry: "询问",
    detail: "细节",

    categories: "类别",
    product_info: "产品信息",
    send_email: "发送电子邮件给我们",

    //faq
    faq_about_us: "想与我们合作？",
    faq_1_question: "你们的价格是多少？",
    faq_1_answer: "我们的价格可能会根据供应和其他市场因素而变化。在您的公司联系我们了解更多信息后，我们将向您发送更新的价目表。",
    faq_2_question: "你们有最低订购量吗？",
    faq_2_answer: "是的，我们要求所有国际订单都有持续的最低订购量。如果您想转售但数量少得多，我们建议您查看我们的网站。",
    faq_3_question: "能提供相关文件吗？",
    faq_3_answer: "是的，我们可以提供大多数文件，包括分析/合格证书；保险；原产地以及其他需要的出口文件。",
    faq_4_question: "平均交货时间是多少？",
    faq_4_answer: "对于样品，交货时间约为 7 天。对于批量生产，交货时间为收到定金后 20-30 天。当我们收到您的定金并且获得您对您的产品的最终批准时，交货时间即生效。如果我们的交货时间与您的截止日期不符，请在销售时仔细检查您的要求。在任何情况下，我们都会尽力满足您的需求。在大多数情况下我们能够这样做。",
    faq_5_question: "你们接受哪些付款方式？",
    faq_5_answer: "您可以向我们的银行账户、西联汇款或 PayPal 付款：预付 30% 定金，70% 余额凭提单副本付清。",
    faq_6_question: "什么是产品保修？",
    faq_6_answer: "我们保证我们的材料和工艺。我们的承诺是让您对我们的产品感到满意。无论是否保修，我们公司的文化是解决所有客户问题，让每个人都满意。",
    faq_7_question: "您能保证安全可靠地交付产品吗？",
    faq_7_answer: "是的，我们始终使用高品质的出口包装。我们还使用专门的危险品包装来包装危险品，并使用经过验证的冷藏托运商来包装对温度敏感的物品。专业包装和非标准包装要求可能会产生额外费用。",
    faq_8_question: "运费怎么样？",
    faq_8_answer: "运费取决于您选择的取货方式。快递通常是最快但也是最昂贵的方式。海运是大批量货物的最佳解决方案。只有知道数量、重量和方式的详细信息，我们才能给您准确的运费。请联系我们获取更多信息。",

    //news
    bms_rivacy_policy: "极空BMS隐私政策",
    encyclopedia_of_lithium_batteries: "锂电池百科",
    media_information: "媒体信息",
    industry_information: "行业信息",
    read_more: "阅读更多",
    media_information_content: "媒体信息",
    industry_information_content: "行业信息",

    //about us
    our_company: "我们公司",
    company_exterior_view: "公司外景图",
    our_company_content: "深圳市极空科技有限公司成立于2017年，集研发、生产、销售于一体，专业生产锂电池保护板，如LiFePO4 BMS、NMC BMS、LTO BMS，可用于能源仓储、电动车、电动工具、电动轮椅、AGV、叉车等。极空BMS的规格有4S-25S、24v-120v、5A-500A。Smart BMS、BT、UART、RS485、can、LCD和GPS在欧洲、亚洲和北美非常流行。我们努力继续开发更多高科技的BMS。欢迎亲临我司参观，希望与您建立长期的合作关系。",

    //contact us
    address: "地址",

    //technical support
    user_manual: "用户手册",
    product_video: "产品视频",
    download_software: "下载软件",
    firmware_temporary_password: "固件临时密码",
    download_pc: "下载PC软件",
    download_bluetooth: "下载蓝牙软件",
    download: "下载",

    //product video
    smart_bms: "智能BMS",

    //page
    page_num: '页数',
    next_page: '上一页',
    prev_page: '下一页',
};
