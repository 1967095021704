import { getData } from '../services/request';
import router from '../router';

const API = '/api/user';
let url;

//登录
const loginFC = function ({commit}, body){
    url = `${API}/login`;
    getData(url, body, (databack) => { 
        // console.log(databack);
        if(databack.msg === "ok"){
            commit('login', databack.data);
            alert("登录成功");
            router.push("/home");
        }else{
            alert(databack.msg);
        }
    });
    //postData(url, body);
    //console.log(body)
}

const enterDevice = function ({commit}, data){
    
    if(data !== ""){
        commit('sendDeviceData', data);
    }else{
        alert(data);
    }
}

export default { loginFC, enterDevice }
