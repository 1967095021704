
import { createStore } from 'vuex'
import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import createVuexAlong from 'vuex-along'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    plugins: [createVuexAlong()]
});

export default store;
