import axios from 'axios';
import store from '@/store/index';
import router from '@/router';

const service = axios.create({
    // 如果指定baseURL 那么请求中就会带上该ip，开发时一般不用，用proxy做跨域代理开发,要部署上线时指定服务器地址
    // baseURL: ip,  //请求地址   
     timeout: 5000 //超时时间
});

// 跨域请求发送cookies
service.defaults.withCredentials = true;
// 请求类型
service.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
// 请求数据方式
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//设置请求拦截器
service.interceptors.request.use(
    config => {
        // console.log(config) // 该处可以将config打印出来看一下，该部分将发送给后端（server端）
        // config.headers.common['x-token'] = store.state.loginInfo.token;
        if(localStorage.LANG === 'chinese' || localStorage.LANG === undefined){
            config.headers.common['Accept-Language'] = 'zh_CN';
        }else if(localStorage.LANG == 'english') {
            config.headers.common['Accept-Language'] = 'en_US';
        }else{
            console.log(localStorage);
        }

        return config; // 对config处理完后返回，下一步将向后端发送请求
    },
    error => {
        // 当发生错误时，执行该部分代码
        // console.log(error) // 调试用
        return Promise.reject(error);
    }
)

//设置响应拦截器
service.interceptors.response.use(function (response) {

    if (response.status == 200) {
        if (!response.headers['content-type'].toLowerCase().startsWith('application/json')) {
            return response.data;
        }
        if (response.data.retCode != '000000') {
            window.alert(response.data.retMsg);
            return Promise.resolve(response.data);
        }
        if (response.data.errCode != '000000') {
            window.alert(response.data.errMsg);
            return Promise.resolve(response.data);
        } 
        return Promise.resolve(response.data);
    } else {
        window.alert("网络错误!");
    }
}, 
function (error) {
    window.alert(error.response.data.errCode ? error.response.data.errMsg : '请求错误');
    return Promise.reject(error.response);
})

function getDataPromise(url){
    // console.log(url);
    return service({
        method: 'get',
        url: url,
    });
}

async function getData(url, callback) {
    let dataObj;
    // console.log(url);
    dataObj = await service({
        method: 'get',
        url: url,
    });
    //数据传到回调
    // console.log(dataObj);
    
    callback(dataObj);
    return dataObj;
}

function postData(url,body){
    // console.log(url);
    return service({
        method: 'post',
        url: url,
        data: body
    })
}
export { getDataPromise, getData, postData };
