import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang'

import { router } from './router.js'
import store from './store'
//import axios from 'axios'
//import VueAxios from 'vue-axios'

import './assets/css/global.css'
import './assets/css/layout.css'
import './assets/css/icon.css'

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(store);
app.mount('#app');
//app.provide('axios', app.config.globalProperties.axios);
