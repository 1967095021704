<template>
<div>
  <router-view v-if="isRouter"></router-view>
</div>
</template>

<script setup>
//刷新函数
import { nextTick, provide, ref } from "vue";
const isRouter = ref(true);
const reload = () => {
  isRouter.value = false;
  nextTick(() => {
    isRouter.value = true;
  });
};
provide("reload", reload);
</script>

<style>
</style>
